#product-mobile-view {
    margin-left: -12px;
    margin-right: -12px;
}

#product-mobile-view > * {
    background-color: #fff;
    border-bottom: 2px solid #ddd;
}

#product-mobile-view > *:first-child {
    border-top: 2px solid #ddd;
}

#product-mobile-view > *:nth-child(even) {
    background-color: #f5f5f5;
}
