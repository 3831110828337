@import './variables.scss';
@import '~react-datepicker/src/stylesheets/datepicker';

.react-datepicker__triangle {
    left: -50px !important;
}

.form-floating {
    .react-datepicker-wrapper {
        padding: 0;
        margin: 0;
        border: 0;
        .react-datepicker__input-container {
            @extend .form-floating;

            padding: 0;
            margin: 0;
            border: 0;

            .form-control {
                @extend .form-control;
            }
        }
    }
}

.datepicker-label,
.form-floating {
    @extend .form-floating;

    > label {
        opacity: 0.65;
        transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
    }
}

.react-datepicker__day-name {
    color: white;
}
