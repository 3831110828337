@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.form-accordion {
    border-radius: 0;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
        & {
            border-radius: 0.5rem;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

