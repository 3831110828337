$_sp-light-gray: #f5f5f5;
$_side-nav: #002b3b;

$theme-colors: map-merge(
    $theme-colors,
    (
        'sp-light-gray': $_sp-light-gray,
        'side-nav': $_side-nav,
    )
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

$body-bg: $_sp-light-gray;

$input-group-addon-bg: $white;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-bg: $_sp-light-gray;
$table-striped-bg: $white;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start box-shadow-variables
$box-shadow: 0.25rem 0.5rem 0.8rem -0.6rem rgba($black, 0.4); //0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);
// scss-docs-end box-shadow-variables

// scss-docs-start form-floating-variables
$form-floating-height: add(2.75rem, $input-height-border);
$form-floating-padding-x: $input-padding-x;
// scss-docs-end form-floating-variables

$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);

$utilities-text: map-merge($utilities-text, $utilities-colors);

$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');

$utilities-bg: map-merge($utilities-bg, $utilities-colors);

$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');

// scss-docs-start navbar-theme-variables
$navbar-dark-color: rgba($white, 1);

$navbar-height: calc(#{$navbar-padding-y} * 2 + 50px);
