// checkout: https://getbootstrap.com/docs/5.0/customize/sass/

// scss-docs-start import-stack
// Configuration

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import './variables/_before-variables';

// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';

// 4. Include any custom and extending of variables here
@import './variables/_after-variables';

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

//Generate utilites for Cursor
$utilities: map-merge(
    $utilities,
    (
        'cursor': (
            property: cursor,
            values: auto default pointer wait text move help not-allowed,
        ),
    )
);

// 5. Include any optional Bootstrap components as you like
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';

// componets
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '_carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';
// scss-docs-end import-stack

// 5. Add additional custom code here

@import './datepicker/datepicker.scss';

@import "./react-select/react-select.scss";
html {
    scroll-padding-top: #{$navbar-height};
}

body {
    margin-right: auto !important; // Fix for ugly lightbox
    overflow: auto !important; // (It tries to hide the scrollbar which causes UI jump/scale)
}

h1 {
    font-size: 48px;
}

#root {
    .App {
        .page {
            display: flex;
            flex: 1;
            flex-direction: column;
            // Veiw - Header - Footer - Margin
            min-height: calc(
            100vh - (52px + 8px * 2) - (180px + 24px * 2) - 30px
            );
            margin-bottom: 30px;
            padding-left: 80px;
            padding-right: 80px;

            @include media-breakpoint-down(md) {
                padding-left: inherit;
                padding-right: inherit;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .admin-nav-link {
            font-size: small;
            font-weight: bolder;
            @include media-breakpoint-down(lg) {
                font-size: smaller;
                padding-top: 0.5rem !important;
                padding-bottom: 0.5rem !important;
            }
        }

        .breadcrumb {
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
        .breadcrumb-item:first-of-type {
            font-weight: 600 !important;
            color: var(--bs-body-color) !important;
        }
        .breadcrumb * {
            text-decoration: none;
            text-transform: uppercase;
        }

        #sidebar-wrapper .sidebar-heading {
            padding: 0.875rem 1.25rem;
            font-size: 1.2rem;
        }

        #page-content-wrapper {
            min-width: 0;
            margin-top: #{$navbar-height}; // Change 72px to 70px -> Unecessary scrollbar disappeares if content is smaller than screen
        }

        .sidebar {
            position: fixed;
            z-index: 100;
            @include media-breakpoint-down(lg) {
                right: 0;
                left: 0;
                bottom: 0;
            }
            @include media-breakpoint-up(lg) {
                bottom: 0;
                left: 0;
                min-height: calc(100vh - #{$navbar-height});
                z-index: 100;
                box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
            }

            @include media-breakpoint-down(sm) {
                .admin-nav-link {
                    font-size: 0.58rem;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .inner-sidebar {
                min-height: calc(100vh - #{$navbar-height});
                box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
                overflow-x: auto;
                height: 100%;
                background-color: rgb(235, 235, 235) !important;
            }

            #sidebar-wrapper {
                min-width: 110px;
                -webkit-transition: margin 0.25s ease-out;
                -moz-transition: margin 0.25s ease-out;
                -o-transition: margin 0.25s ease-out;
                transition: margin 0.25s ease-out;
                .sidebar {
                    min-width: 110px;
                }
            }

            #inner-sidebar-wrapper {
                width: calc(286.188px + 24px);
                padding: 0px;
            }
        }
        @include media-breakpoint-down(sm) {
            .w-100-below-sm {
                width: 100%;
            }
        }
    }
}

.modal-backdrop.show {
    background-color: #014e5c;
    opacity: 0.8;
}

.modal-content {
    border-radius: 0;
    padding: 8px 24px;
}

.modal-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    margin: 16px 0;
}

.btn-secondary > *,
.btn-secondary,
.btn-secondary:hover {
    color: $white;
}

.btn-proj {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}