.recursive-category-list {
    * {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul {
        li {
            ul {
                font-weight: 300;

                li {
                    ul {
                        font-weight: 200;
                        li {
                        }
                    }
                }
            }
        }
    }

    ul > li > ul {
        margin-left: 0.4rem;
    }

    .categorie-name:hover {
        color: #ff9500bf;
    }

    .recursive-category-list-toggler {
        font-weight: 400;
    }
}
