$admin_dark_color: #d6d7d7;

table.sys-admin > thead > tr > * {
    background-color: $admin_dark_color;
}

.sys-admin.table-striped > tbody > tr > * {
    background-color: #f5f5f5;
}

.sys-admin.table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: #fbfafb;
}

.sys-admin-footer {
    background-color: $admin_dark_color;
}

table.excel-import-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
