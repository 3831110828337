@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.dnd-img-thumb {
    display: inline-flex;
    justify-content: center;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;
}

.dragging {
    opacity: 0.3;
}

.cover {
    min-width: 150px;
    width: 150px;
    min-height: 150px;
    height: 150px;

    @include media-breakpoint-down(xs) {
        width: auto;
        max-width: 500px;
        height: 100%;
        max-height: 500px;
    }
}
