.dnd-img {
    display: block;
    width: auto;
    height: 100%;
}

.thumb-inner {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}
