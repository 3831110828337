@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.dropzone {
    border-radius: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100px;

    @include media-breakpoint-up(sm) {
        min-height: 200px;
    }
}

.dropzone .pointer {
    cursor: pointer;
}
