.public-dot {
    background-color: #4caf50;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin-left: 5px;
    margin-top: 5px;
    position: absolute;
    border: 2px solid #fff;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.6);
}
