.react-select-container {
    position: relative;

    .react-select__control {
        @extend .form-control;
        display: flex;
        width: 100%;
        flex: 1;
        padding: 0;

        min-height: $input-height;
        height: $input-height;

        .react-select__value-container {
            min-height: $input-height;
            height: $input-height;

            width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .select__input {
                margin: 0px;
            }
        }

        .react-select__indicators {
            height: $input-height;

            .react-select__indicator {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .react-select__menu {
        margin: 0;
    }
}
.react-select--is-disabled {
    background-color: #e9ecef;
}
