#project-mobile-view {
    margin-left: -12px;
    margin-right: -12px;
}

#project-mobile-view > * {
    background-color: #fff;
    padding: 0.5rem 0;
    border-bottom: 2px solid #ddd;
}

#project-mobile-view > *:first-child {
    border-top: 2px solid #ddd;
}

#project-mobile-view > *:nth-child(even) {
    background-color: #f5f5f5;
}
