$blue: #003b4d;
$red: #cf0000;
$green: #4caf50;
$orange: #ff9300;

$secondary: $orange;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$line-height-base: 1.15;
$font-size-base: 0.8rem !default; // Assumes the browser default, typically `16px`
$line-height-base: 1.35;

$border-radius: 0.65rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 1rem !default;

// Buttons + Forms

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y: 0.275rem;
$btn-padding-x: 2rem;

$btn-border-radius: 0.65rem;
$btn-border-radius-sm: 0.55rem;
$btn-border-radius-lg: 0.25rem;

$btn-padding-y: 0.5rem;

// scss-docs-start form-floating-variables
$form-floating-input-padding-t: 1rem;
$form-floating-input-padding-b: 0.15rem;
$form-floating-label-transform: scale(0.75) translateY(-0.5rem)
    translateX(0.15rem) !default;
// scss-docs-end form-floating-variables

$input-border-radius: $btn-border-radius;
$input-border-radius-sm: $btn-border-radius-sm;
$input-border-radius-lg: $btn-border-radius-lg;

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size: null !default;
$breadcrumb-margin-bottom: 0rem !default;
$breadcrumb-divider: quote('>') !default;
// scss-docs-end breadcrumb-variables
