.thumb {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.thumb:first-of-type {
    flex-basis: 100%;
}
