@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.placements-table {
    --bs-bg-opacity: 0.8;
    --bs-active-opacity: 1;

    border-radius: 1rem;

    &-item {
        background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));

        margin-left: -1rem;
        margin-right: -1rem;

        &:nth-child(even) {
            background-color: rgba(
                var(--bs-sp-light-gray-rgb),
                var(--bs-bg-opacity)
            );
        }

        &:hover {
            background-color: var(--bs-gray-200);
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }

        @include media-breakpoint-up(sm) {
            & {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &-item.disabled {
        opacity: 0.5;
    }

    &-header,
    &-footer {
        padding: 0.4rem;
        background-color: rgba(
            var(--bs-sp-light-gray-rgb),
            var(--bs-bg-opacity)
        );
    }

    &-radio {
        flex: 0 1 30px;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;

        input[type='radio'] {
            width: 20px;
            height: 20px;
        }
    }

    &-radio,
    &-amount {
        padding: 0.2rem;
    }

    &-amount {
        text-align: right;
        justify-content: space-around;
        align-items: center;

        &-value {
        }

        &-button {
            --btn-size: 50px;
            width: var(--btn-size);
            height: var(--btn-size);
            line-height: var(--btn-size);
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ccc;

            &-minus {
                border-radius: 50% 0 0 50%;
            }

            &-plus {
                border-radius: 0 50% 50% 0;
            }

            @include media-breakpoint-up(lg) {
                & {
                    --btn-size: 30px;
                    border-radius: 50%;
                    font-size: 1rem;
                }
            }
        }
    }
}
