// Notes on the classes:
//
// 1. .carousel.pointer-event should ideally be pan-y (to allow for users to scroll vertically)
//    even when their scroll action started on a carousel, but for compatibility (with Firefox)
//    we're preventing all actions instead
// 2. The .carousel-item-start and .carousel-item-end is used to indicate where
//    the active slide is heading.
// 3. .active.carousel-item is the current slide.
// 4. .active.carousel-item-start and .active.carousel-item-end is the current
//    slide in its in-transition state. Only one of these occurs at a time.
// 5. .carousel-item-next.carousel-item-start and .carousel-item-prev.carousel-item-end
//    is the upcoming slide in transition.

@import '~bootstrap/scss/carousel';

.carousel-caption {
    right: 0;
    top: 50%;
    text-align: left;
    transform: translate(0, -50%);
}

.carousel-item {
    min-height: 450px;
    background: $primary;
}

.overlay {
    opacity: 0.4;
}
